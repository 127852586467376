import React from 'react'
import useRemark from 'hooks/useRemark'
import Component from './index.js'
import { extractGroup } from '../StoryblokGrid'

export default function EditableTransformer({ data, ...props }) {
  const textContent = useRemark(data.textContent)
  const button = extractGroup(data.button || data, [
    'anchor',
    'label',
    'color',
    'textColor',
  ])

  return <Component data={{ ...data, textContent, button }} {...props} />
}
